import Typography from '@material-ui/core/Typography'
import { format } from 'date-fns'
import React from 'react'
import DoneIcon from '../../../../assets/Icon-done.svg'
import MedicationIcon from '../../../../assets/Icon-medication.svg'
import NotDoneIcon from '../../../../assets/Icon-notdone.svg'
import TimeColorIcon from '../../../../assets/Icon-time-color.svg'
import {
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StatusContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles'
import { CardBox } from './styles'
import { handleDropsLabel } from './utils'

interface CardProps {
  id?: Id
  name?: string
  status?: string
  dosageQuantity?: number
  dosageFormat?: string
  administeredBy?: string
  medicalNotes?: string
  eventHappensAt?: string
  updatedTimeHappensAt?: string | null
  comment?: string
  caregiverName?: string
}

const MedicationCard: React.FC<CardProps> = ({
  id,
  name,
  status,
  dosageQuantity,
  dosageFormat,
  administeredBy,
  medicalNotes,
  eventHappensAt,
  updatedTimeHappensAt,
  comment,
  caregiverName,
}) => {
  const eventDate = updatedTimeHappensAt || eventHappensAt
  const formattedDate = format(new Date(`${eventDate}`), 'HH:mm')
  let doseFormat
  switch (dosageFormat) {
    case 'pill':
      doseFormat = dosageQuantity == 1 ? 'comprimido' : 'comprimidos'
      break

    case 'capsule':
      doseFormat = dosageQuantity == 1 ? 'cápsula' : 'cápsulas'
      break

    case 'sachet':
      doseFormat = dosageQuantity == 1 ? 'sachê' : 'sachês'
      break

    case 'drops':
      doseFormat = handleDropsLabel(dosageQuantity)
      break

    case 'ampoule':
      doseFormat = dosageQuantity == 1 ? 'ampola' : 'ampolas'
      break

    case 'suppository':
      doseFormat = dosageQuantity == 1 ? 'supositório' : 'supositórios'
      break

    case 'inhaled':
      doseFormat = dosageQuantity == 1 ? 'jato/puff' : 'jatos/puffs'
      break

    case 'unit':
      doseFormat =
        dosageQuantity == 1
          ? 'unidade internacional'
          : 'unidades internacionais'
      break

    case 'ml':
      doseFormat = 'ml'
      break

    case 'spoon':
      doseFormat = dosageQuantity == 1 ? 'colher' : 'colheres'
      break

    case 'grams':
      doseFormat = dosageQuantity == 1 ? 'grama' : 'gramas'
      break

    case 'strip':
      doseFormat =
        dosageQuantity == 1 ? 'placa/tira/fita' : 'placas/tiras/fitas'
      break

    case 'sticker':
      doseFormat = dosageQuantity == 1 ? 'adesivo' : 'adesivos'
      break

    default:
      break
  }

  let viaType
  switch (administeredBy) {
    case 'oral':
      viaType = 'Oral'
      break

    case 'sublingual':
      viaType = 'Sublingual'
      break

    case 'subcutaneous':
      viaType = 'Subcutânea'
      break

    case 'intravenous':
      viaType = 'Intravenosa'
      break

    case 'intramuscular':
      viaType = 'Intramuscular'
      break

    case 'tube':
      viaType = 'Sonda'
      break

    case 'rectal':
      viaType = 'Retal'
      break

    case 'inhalation':
      viaType = 'Inalatório'
      break

    case 'topic':
      viaType = 'Cutânea'
      break

    case 'ocular':
      viaType = 'Ocular'
      break

    case 'auricular':
      viaType = 'Auricular'
      break

    default:
      break
  }

  return (
    <CardBox data-testid={`medicationCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={MedicationIcon} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="subtitle2">
            {dosageQuantity} {doseFormat}
          </Typography>
          <Typography variant="subtitle2">Via: {viaType}</Typography>
          <Typography variant="subtitle2">{medicalNotes}</Typography>
          <DescriptionContainer>
            {comment && (
              <Typography variant="subtitle2">Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          <StatusContainer>
            {updatedTimeHappensAt && status === 'accomplished' && (
              <img src={TimeColorIcon} data-testid={`delayedIcon-${id}`} />
            )}
            {status === 'awaiting' && <></>}
            {status === 'accomplished' && <img src={DoneIcon} />}
            {status === 'not_accomplished' && <img src={NotDoneIcon} />}
          </StatusContainer>
        </CardFooter>
      </RightContainer>
    </CardBox>
  )
}

export default MedicationCard
